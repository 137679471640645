import * as CONSTANTS from '../../modules/constants';
import { Gallery } from '../../modules/gallery';

export class PDP {
    private stickyCTAElement: HTMLElement;
    private stickyCTAContainerElement: HTMLElement;
    private activeStickyCssClass: string = "subnav-mobile-sticky-active";
    constructor() {
        this.init();
    }

    init() {
        this.activateUpcomingCarousel();
        this.activateStickyCTAButton();
    }
    activateUpcomingCarousel() {
        //Activate Galleries/Carousels
        const upcomingGallery = document.querySelector('[data-content-upcoming-gallery]');
        const contentGalleryCssClass = 'adage-gallery';
        upcomingGallery.classList.add(`${contentGalleryCssClass}-upcomingDates`);
        const prevButton = document.getElementById(`contentGalleryPrev_${upcomingGallery.getAttribute("data-unique-id")}`);
        const nextButton = document.getElementById(`contentGalleryNext_${upcomingGallery.getAttribute("data-unique-id")}`);
        new Gallery({
            containerClass: `${contentGalleryCssClass}-upcomingDates`,
            autoHeight: false,
            slidesPerView: 8,
            loop: false,
            spaceBetween: 24,
            allowVideo: false,
            breakpoints: {
                1100: {
                    slidesPerView: 3
                },
                1300: {
                    slidesPerView: 4
                },
                1500: {
                    slidesPerView: 5
                },
                1700: {
                    slidesPerView: 6
                },
                1800: {
                    slidesPerView: 7
                }
            },
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
        })
    }

    stickyCTA() {
        let stickyCTAElementHeight = 0;
        if (this.stickyCTAElement) {
            stickyCTAElementHeight = this.stickyCTAElement.getBoundingClientRect().height;
        }
        const heightAdjustments = (CONSTANTS.GLOBAL_HEADER as HTMLElement).getBoundingClientRect().height - stickyCTAElementHeight;   
        const options = {
            root: null,
            rootMargin: heightAdjustments + 'px', 
            threshold: 1
        }
        const handler = (entries) => {
            if (!entries[0].isIntersecting) {
                CONSTANTS.GLOBAL_HTML.classList.add('subnav-stick-mobile-active');
                this.stickyCTAContainerElement.style.paddingTop = this.stickyCTAElement.getBoundingClientRect().height + "px";
            }
            else {
                CONSTANTS.GLOBAL_HTML.classList.remove('subnav-stick-mobile-active');
                this.stickyCTAContainerElement.removeAttribute('style');
            }
        }
        const observer = new IntersectionObserver(handler, options);
        // give the observer some dom nodes to keep an eye on
        observer.observe(this.stickyCTAContainerElement)
    }

    activateStickyCTAButton() {
        if (document.querySelector("[data-pdp-sticky-container]")) {
            this.stickyCTAContainerElement = (document.querySelector("[data-pdp-sticky-container]") as HTMLElement);
            this.stickyCTAElement = (document.querySelector("[data-pdp-sticky]") as HTMLElement);
            if (this.stickyCTAContainerElement && this.stickyCTAElement) {
                window.addEventListener('scroll', () => {
                    this.stickyCTA();
                });
            }
        }
    }
}

(function playDetails() {
    new PDP(); 
})();